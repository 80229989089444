<template>
 <div>
  <b-card bg-variant="light">
    <b-form @submit="onSubmit">
      <b-form-group 
        label-cols-lg="3"
        label="Forgot Password"
        label-size="lg"
        label-class="font-weight-bold pt-0"
        class="mb-0"
      >
        <b-form-group
          label="Email Address:"
          label-for="nested-email-address"
          label-cols-sm="3"
          label-align-sm="right"
        >
          <b-form-input id="nested-email-address"
            v-model="user.email"
          ></b-form-input>
        </b-form-group>
      </b-form-group>
      <br>
      <b-row>
        <b-col md="3"/>
        <b-col md="6">
          <b-button type="submit" block variant="primary">Send Email for Password Reset</b-button>
        </b-col>
      </b-row>
    </b-form>

  </b-card>
 </div>
</template>

<script>
import {http_v1} from '@/axios'

export default {
  data () {
    return {
      user:{
        email: '',
      }
    }
  },
  methods: {
    onSubmit(event) {
      event.preventDefault()
      http_v1.post (
        'password_forgot', {
          email: this.user.email,
          reset_url:  window.location.origin /* + this.$route.path */ + '/password-reset'
        }
      ).then(response => {
        if(response.data && response.data.auth_token) {
          localStorage.token = response.data.auth_token
          this.$router.back()
        }
      }).catch(error => {
        if( error.response && error.response.data) {
          var message = error.response.data.message || JSON.stringify(error.response.data)
          if (error.response.status === 500 ) message = "500 Server Error"
          this.$bvToast.toast(message, {
            title: 'Error',
            autoHideDelay: 5000,
            appendToast: true
          })
        }
      })
    }
  }
}
</script>